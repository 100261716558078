
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { mapState, mapActions } from 'vuex'

import moment from 'moment'

export default Vue.extend({
  name: 'Devices',
  data () {
    return {
      switchConfigMode: true,
      selectedUsers: [],
      selectedLock: [],
      allowedMinutes: (v: number) => { v == 0 },
      // headersBookings: [
      //   { text: this.$t('devices.table_col_effective'), value: 'effectiveTime' },
      //   { text: this.$t('devices.table_col_invalid'), value: 'invalidTime' },
      //   { text: this.$t('devices.table_col_booking_password'), value: 'password', sortable: false },
      // ],
      effectiveTime: '10:00',
      invalidTime: '11:00',
      effectiveDate: new Date().toISOString().substr(0, 10),
      invalidDate: new Date().toISOString().substr(0, 10),
      loading: false
    }
  },
  computed: {
    headersBookings() {
      return [
        { text: this.$t('devices.table_col_effective'), value: 'effectiveTime' },
        { text: this.$t('devices.table_col_invalid'), value: 'invalidTime' },
        { text: this.$t('devices.table_col_booking_password'), value: 'password', sortable: false },
      ]
    },
    headersUsers() {
      return [
        { text: this.$t('devices.table_col_username'), value: 'username' },
        { text: this.$t('devices.table_col_device_ids'), value: 'deviceIds', sortable: false },
      ]
    },
    headersLocks() {
      return [
        { text: this.$t('devices.table_col_device_icon'), value: 'icon', sortable: false },
        { text: this.$t('devices.table_col_device_name'), value: 'deviceName' },
        { text: this.$t('devices.table_col_device_id'), value: 'deviceId' },
        { text: this.$t('devices.table_col_issue_dynamic'), value: 'dynamicPassword', sortable: false },
        { text: this.$t('devices.table_col_dynamic_password'), value: 'password', sortable: false },
      ]
    },
    effectiveDatetime () {
      return this.localMoment(this.effectiveDate + ' ' + this.effectiveTime)      
    },
    invalidDatetime () {
      return this.localMoment(this.invalidDate + ' ' + this.invalidTime)
    },
    ...mapState({
      users: (state: any) => state.users.users,
      clientName: (state: string) => state.auth.client.clientName,
      keyPadDevices: (state: any) => state.devices.devices.filter(device => device.withKeypad),
      devices: (state: any) => state.devices.devices,
      bookings: (state: any) => state.passwords.bookings,
      locale: (state: any) => state.locale,
      userGroup: (state: any) => state.auth.userGroup
    }),
  },
  watch: {
    selectedLock: async function(val) {
      if (val.length > 0) {
        // console.log(`selectedLock: ${JSON.stringify(val[0])}`)

        if (val[0].withKeypad) {
          let error = await this.loadBookings({deviceId: val[0].deviceId}).catch(err => {
            return err
          })

          if (error) {
            window.alert(error)
            console.log(`loadBookings error ${error.message}`)
            this.$router.push({name: 'Logout'})
          }          
        }

      }
    },
    selectedUsers: async function(vals) {
      // console.log(`selectedUsers: ${JSON.stringify(vals.length)}`)

      this.users.forEach(user => {
        user.selected = false

        const crtSelectedUser = vals.find(val => {
          return val.username == user.username
        })

        if (crtSelectedUser) {
          crtSelectedUser.selected = true
        }

        
      })

    }
  },
  methods: {
    localMoment: function (datetimeValue) {
      if (this.locale == 'jp') {
        return moment(datetimeValue).locale('ja').format('LLL')  
      } else {
        return moment(datetimeValue).locale('en-gb').format('LLL')  
      }      
    },    
    async saveUsers() {
      console.log('selectedUsers', this.selectedUsers)

      this.loading = true

      let error = await this.updateUsers(this.selectedUsers).catch(err => {
        return err
      })

      if (error) {
        window.alert(error)
        this.$router.push({name: 'Logout'})
      }

      this.loading = false
    },
    async refresh(){
      this.loading = true

      let error = await this.refreshDevices().catch(err => {
        return err
      })

      if (error) {
        window.alert(error)
        this.$router.push({name: 'Logout'})
      }

      this.loading = false
    },
    async makeDynamicPassword(pickedLock) {
      // console.log(`makeDynamicPassword pickedLock ${JSON.stringify(pickedLock)}`)

      this.loading = true

      try {

        const dynamicPassword = await this.getDynamicPassword({
          deviceId: pickedLock.deviceId
        })

        pickedLock.password = dynamicPassword

        this.updateDevices(pickedLock)

      } catch(err) {
        window.alert(err.message)
        this.$router.push({name: 'Logout'})
      }

      this.loading = false

    },
    async book(){

      this.loading = true

      try {

        const effectiveFrom = moment(this.effectiveDate + ' ' + this.effectiveTime)
        const invalidFrom = moment(this.invalidDate + ' ' + this.invalidTime)

        await this.placeBooking({
          deviceId: this.selectedLock[0]?.deviceId,
          effectiveTime: effectiveFrom.unix(),
          invalidTime: invalidFrom.unix(),
        })

      } catch(err) {
        window.alert(err.message)
        this.$router.push({name: 'Logout'})
      }

      this.loading = false

    },
    async remove(){

      this.loading = true

      console.log('removeInvalidBookings deviceId', this.selectedLock[0]?.deviceId)

      try {

        await this.removeInvalidBookings({
          deviceId: this.selectedLock[0]?.deviceId
        })

      } catch(err) {
        window.alert(err.message)
        this.$router.push({name: 'Logout'})
      }

      this.loading = false

    },
    ...mapActions({
      updateUsers: 'users/updateUsers',
      listUsers: 'users/listUsers',
      loadBookings: 'passwords/loadBookings',
      placeBooking: 'passwords/placeBooking',
      refreshDevices: 'devices/refreshDevices',
      getDynamicPassword: 'passwords/getDynamicPassword',
      updateDevices: 'devices/updateDevices',
      removeInvalidBookings: 'passwords/removeInvalidBookings'
    })
  },
  async beforeRouteEnter (to, from, next) {

    let error

    if (store.state.auth.userGroup == 'owner' || store.state.auth.userGroup == 'tenant') {
      error = await store.dispatch('devices/listDevices').catch(err => {
        return err
      })
    } else if (store.state.auth.userGroup == 'user') {
      error = await store.dispatch('users/getUserDevices').catch(err => {
        return err
      })
    }

    let error2

    if (store.state.auth.userGroup == 'owner' || store.state.auth.userGroup == 'tenant') {
      error2 = await store.dispatch('users/listUsers').catch(err => {
        return err
      })
    }

    if (error || error2) {
      console.log(error)
      router.push({name: 'Logout'})

    } else {
      next()
    }

  },
})
